module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"kickbooster","accessToken":"MC5Ydk44LXhJQUFEcG45TVRJ.PSVP77-9Oe-_ve-_ve-_ve-_vQFkEe-_vWJJ77-9PBVqXGDvv73vv704YO-_ve-_vRfvv73vv73vv70F","defaultLang":"en-ca","previews":true,"pages":[{"type":"Post","match":"/blog/:uid/","path":"/blogs","component":"/workspace/src/templates/Article.js"},{"type":"Find_your_survey","match":"/:uid/","path":"/fys","component":"/workspace/src/templates/FindYourSurvey.js"},{"type":"Page","match":"/:uid/","path":"/pages","component":"/workspace/src/templates/Page.js"},{"type":"Page","match":"/blog/:uid/","path":"/b","component":"/workspace/src/templates/Page.js"},{"type":"Success_story","match":"/success/:uid/","path":"/success","component":"/workspace/src/templates/SuccessStory.js"},{"type":"Career","match":"/careers/:uid/","path":"/careers","component":"/workspace/src/templates/Career.js"},{"type":"Pricing_page","match":"/pricing","path":"/price","component":"/workspace/src/templates/PricingPage.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-K9CKQ62","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kickbooster App","short_name":"Kickbooster","start_url":"/","background_color":"#7047E5","theme_color":"#7047E5","display":"minimal-ui","icon":"src/images/kb-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f217b65d1613f47ba7541ce212b07eeb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
