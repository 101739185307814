/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/components/_utils.scss"
import "./src/components/_fonts.scss"
import "./src/components/_button.scss"
import "./src/components/_form.scss"
import "./src/components/layout.scss"